import { Outlet, Link, useLocation } from "react-router-dom";
import { AppContext } from "../App";
import { useContext, useEffect } from "react";
import Label from "./Label";
import Footer from "./Footer";
import { setCookie } from "./functions";
import LoginPopup from "../pages/LoginPopup";
import Cookies from "./Cookies";
import CmsMenu from "./CmsMenu";
import ChengeIcon from "./icons/ChengeIcon";


function Layout() {
	const {
		settings,
		pages,
		lang,
		setLang,
		getTranslatedString,
		user,
		loginPopup,
		track,
		menus,
		setBrand,
		mainSettings,
		hasOrders,
		hasReservations,
		cart,
		setLoginRedirect,
		loadRelated,
		getRelatedProducts,
		setRelatedProducts,
	} = useContext(AppContext);
	const location = useLocation();

	useEffect(() => {
		console.log("related", location);
		if (
			settings.menu_api?.enable_related_products_on_checkout == 1 &&
			location.pathname === "/checkout"
		) {
			if (loadRelated) getRelatedProducts(getCartProductsIds());
			else setRelatedProducts(false);
		}
	}, [location]);

	const swapMenu = () => {
		const el = document.getElementById("menu");
		if (el.classList.contains("visible")) hideMenu();
		else showMenu();
	};

	const showMenu = () => {
		const el = document.getElementById("menu");
		el.classList.add("visible");
	};

	const hideMenu = () => {
		const el = document.getElementById("menu");
		el.classList.remove("visible");
	};

	const getCartProductsIds = () => {
		let res = [];
		for (const product of cart.products) {
			res.push(product.product_id);
		}
		return res;
	};

	return (
		<>
			<div id="header" className="screen-container">
				<div className="header-left">
					{settings.website.url_logo ? (
						<a href={settings.website.url_logo} className="logo">
							{settings.website.logo ? (
								<img src={settings.website.logo} />
							) : (
								settings.global.name
							)}
						</a>
					) : (
						<Link to="/" className="logo" onClick={track}>
							{settings.website.logo ? (
								<img src={settings.website.logo} />
							) : (
								settings.global.name
							)}
						</Link>
					)}
					{mainSettings?.mandant?.childs?.length > 0 && (
						<button
							className="brands-switch"
							onClick={() => {
								setBrand(false);
								setCookie("website-brand", "", -1);
							}}
						>
							<ChengeIcon />
						</button>
					)}
				</div>

				<div className="menu">
					{menus.filter((m) => m.section == "header").length > 0 ? (
						<>
							{menus.map(
								(menu, i) =>
									menu.section == "header" && (
										<CmsMenu data={menu} key={i} type="bubble" />
									)
							)}
						</>
					) : (
						<>
							{hasOrders && (
								<Link
									className="button button-primary hidden-small"
									to="/store"
									onClick={track}
								>
									<Label number={1} />
								</Link>
							)}
							{hasReservations == 1 && (
								<Link
									className="button button-primary hidden-small"
									to="/booking"
									onClick={track}
								>
									<Label number={2} />
								</Link>
							)}
						</>
					)}

					{!user ? (
						<Link
							className="button button-icon"
							to="/login"
							onClick={() => {
								hideMenu();
								track();
								setLoginRedirect(location.pathname);
							}}
						>
							<i className="fas fa-user"></i>
							<Label number={3} />
						</Link>
					) : (
						<Link
							className="button button-icon"
							to="/userboard"
							onClick={() => {
								hideMenu();
								track();
							}}
						>
							<i className="fas fa-user"></i>
							<div className="account-button hidden-small">
								{user.name}
							</div>
						</Link>
					)}
					<button onClick={swapMenu} className="button button-icon menu-button">
						<i className="fas fa-bars"></i>
					</button>
					<div className="select-languages">
						{settings.mandant.multilanguage == 1 && (
							<select onChange={(e) => setLang(e.target.value)} value={lang}>
								{settings.global.it == 1 && (
									<option value="it">ITA</option>
								)}
								{settings.global.en == 1 && <option value="en">ENG</option>}
								{settings.global.zh == 1 && <option value="zh">CIN</option>}
								{settings.global.fr == 1 && (
									<option value="fr">FRA</option>
								)}
							</select>
						)}
					</div>
				</div>
			</div>

			<div id="main">
				<Outlet />
				<Footer />
			</div>

			<div id="menu">
				<div className="container">
					<div className="header">
						<button onClick={swapMenu}>
							<i className="fas fa-times"></i>
						</button>

					</div>
					<div className="content">
						{menus.filter((m) => m.section == "main").length > 0 ? (
							<>
								{menus.map(
									(menu, i) =>
										menu.section == "main" && (
											<CmsMenu data={menu} key={i} type="accordion" />
										)
								)}
							</>
						) : (
							<>
								{hasOrders && (
									<Link
										className="button button-primary hidden-large"
										to="/store"
										onClick={() => {
											hideMenu();
											track();
										}}
									>
										<Label number={1} />
									</Link>
								)}
								{hasReservations && (
									<Link
										className="button button-primary hidden-large"
										to="/booking"
										onClick={() => {
											hideMenu();
											track();
										}}
									>
										<Label number={2} />
									</Link>
								)}
								<Link
									className="button"
									to="/shops"
									onClick={() => {
										hideMenu();
										track();
									}}
								>
									<Label number={29} />
								</Link>
								{settings.website.news == 1 && (
									<Link
										className="button"
										to="/news"
										onClick={() => {
											hideMenu();
											track();
										}}
									>
										<Label number={177} />
									</Link>
								)}
								<Link
									className="button"
									to="/promo"
									onClick={() => {
										hideMenu();
										track();
									}}
								>
									<Label number={157} />
								</Link>
								{pages.map(
									(page, i) =>
										page.menu_header == 1 && (
											<Link
												key={i}
												className="button"
												to={"/cms/" + page.id}
												onClick={() => {
													hideMenu();
													track();
												}}
											>
												{getTranslatedString(page, "title")}
											</Link>
										)
								)}
							</>
						)}
					</div>
					{settings.mandant.multilanguage == 1 && (
						<div className="footer">
							<div className="info">
								{settings.global.company && (
									<div className="title">{settings.global.company}</div>
								)}
								{settings.global.vat && <div>P.Iva {settings.global.vat}</div>}
								{settings.global.taxcode && (
									<div>C.F. {settings.global.taxcode}</div>
								)}
								{settings.global.capital && (
									<div>Capitale Sociale: {settings.global.capital}</div>
								)}
								{settings.global.rea && (
									<div>Numero REA: {settings.global.rea}</div>
								)}
								{pages.map(
									(page, i) =>
										page.menu_footer == 1 && (
											<div key={i}>
												<Link key={i} className="" to={"/cms/" + page.id}>
													{page.title}
												</Link>
											</div>
										)
								)}
								<div>
									<a href="https://www.yellgo.it" target="_blank">
										Powered by YellGo
									</a>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{loginPopup && <LoginPopup />}
			{settings && settings.website.disable_cookies_banner != 1 && <Cookies />}
		</>
	);
}

export default Layout;
